// @ts-nocheck
import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { Layout, PageHeader } from '@ggs/components/layout'
import { Component } from '@ggs/gatsby/components'
import { uniqueId } from 'lodash'
import { Carousel, HcpGatedModal } from '@ggs/components/banners'
import { useI18next } from 'gatsby-plugin-react-i18next'
import Box from '@mui/material/Box'
import { Hero } from '@ggs/components/paragraphs'
import TextGrid from '@ggs/components/paragraphs/TextGrid/TextGrid'

// Assets
import './HcpLandingPage.scss'
import { PageBackgroundVariant } from '@ggs/components/flare/PageBackgroundAccent'

/**
 * @param {Object} props
 * @return {JSX.Element}
 */
const HcpLandingPage = ({ queries: { site = '' } = {}, pageContext, ...props }) => {
  const { changeLanguage } = useI18next()
  const data = pageContext?.data || {}
  const { title = '', subtitle = '', ctas = [] } = data

  // Inject changeLanguage function into page context so can be used in stateless components.
  if (pageContext?.i18n?.changeLanguage) {
    pageContext.i18n.changeLanguage = changeLanguage
  }


  const carouselItems = useMemo(
    () =>
      data?.carouselItems?.carouselItem && data?.carouselItems?.carouselItem.length > 0
        ? data?.carouselItems?.carouselItem.map((item, key) => {
          Object.assign(item, {
            variant: Hero.variant.carousel,
            showCircle: false,
          })
          return item
        })
        : [],
    [data?.carouselItems]
  )

  console.log('Hcp Render', pageContext.data)

  return (
    <Layout
      className="hcp-landing-page"
      site={site}
      pageContext={pageContext}
      backgroundVariant={PageBackgroundVariant.pink}
    >
      <Box
        sx={{
          '.product-grid': {},
        }}
      >
        <PageHeader
          title={title}
          subtitle={subtitle}
          backgroundVariant={PageBackgroundVariant.pink}
        />
        {carouselItems.length > 0 && (
          <Carousel carouselItems={carouselItems} variant={Carousel.variant.default}/>
        )}
        <TextGrid gridItems={ctas}/>
        {data.content.map((item) => (
          <Component key={uniqueId(`content-item_${item.id}`)} type={item.type} {...item} />
        ))}
      </Box>
      <HcpGatedModal requireHcpGating/>
    </Layout>
  )
}

export const query = graphql`
  query hcpPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["global"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default HcpLandingPage
